<template>
  <div class="breadBox">
    <div class="breadbox">
      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        class="breadcrumbDiv"
      >
        <div class="breadcrumbLocation">您现在的位置：</div>
        <el-breadcrumb-item
          v-for="(item, index) in breadList"
          :key="index"
          class="breadspan"
          closable
          type:info
        >
          <!-- router/index的 breadspan,breadcolor === false -->
          <span
            class="spanColorFalse"
            v-if="item.breadspan === false && item.breadcolor === false"
          >
            {{ item.title }}
          </span>
          <span class="spanFalse" v-else-if="item.breadspan === false">
            {{ item.title }}
          </span>
          <router-link v-else :to="item.path" active-class="active">
            {{ item.title }}
          </router-link>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  watch: {},
  computed: {
    breadList () {
      const breadList = []

      this.$route.matched.map((item) => {
        // item.meta.breadcrumb !== false在route.index里  如果写了breadcrumb = false那就隐藏掉那个路径
        if (item.meta && item.meta.title && item.meta.breadcrumb !== false) {
          breadList.push({
            path: item.path,
            title: item.meta.title,
            breadspan: item.meta.breadspan,
            breadcolor: item.meta.breadcolor
          })
        }
      })
      // 在面包屑最前面添加上首页路径  unshift：头部添加
      breadList.unshift({
        path: '/homepage',
        title: '首页'
      })
      console.log(breadList)
      return breadList
    }
  },
  methods: {
    isHome (route) {
      console.log(route)
      return route.path === '/home'
    }
  }
}
</script>

<style lang="scss">
.breadBox {
  width: 100%;
}

.breadbox {
  position: relative;
  background-color: #f7f7f7;
  padding-left: 360px;
}

.breadcrumbDiv {
  display: flex;
  align-items: center;
}

.breadcrumbLocation {

  color: #999999;
  font-size: 14px;
}

.spanColorFalse {
  color: #2e88e4;
}

.spanFalse {
  color: #999999;
}

.active {
  text-decoration: none;
  font-size: 14px;
}

.breadspan {
  height: 42px;
  line-height: 42px;
  text-align: center;
  text-decoration: none;
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
}

.breadspan a {
  text-decoration: none;
  color: #999999;
  font-weight: normal;
}

.el-breadcrumb__item:last-child .el-breadcrumb__inner a {
  color: #2e88e4;
}
</style>
