<template>
  <div>
    <optionscard :deliverParentNum="1"></optionscard>
    <breadcrumb></breadcrumb>
    <!-- 1200宽度的div（主体区域） -->
    <div class="width1200">
      <div>
        <ul class="ulul">
          <div class="middleDiv">
            <!-- 左边分类（点击切换攻略） -->
            <div>
              <div class="classifyLeftDiv">
                <div class="classify-left">分类</div>
                <!-- blue1高亮 white1白色 -->
                <li
                  @click="switchClassify(item.category_id)"
                  :class="
                    bzglarticle.cate_id == item.category_id ? 'blue1' : 'white1'
                  "
                  v-for="item in classifyList"
                  :key="item.category_id"
                >
                  {{ item.name }}
                </li>
              </div>
            </div>
            <!-- 右边文章部分 -->
            <div class="classify-right" v-if="bzglList">
              <template v-if="bzglList.length">
                <div
                  class="classify-right-div"
                  @click="getArticle(item.id)"
                  v-for="item in bzglList"
                  :key="item.id"
                >
                  <!-- 左边的图 -->
                  <div class="left-picture">
                    <img :src="item.image" width="100%" height="100%" />
                  </div>
                  <!-- 右边的字 -->
                  <div class="rightWord">
                    <!-- 标题 -->
                    <div class="classify-title">
                      {{ item.title }}
                    </div>
                    <!-- 文字 -->
                    <div class="rightContent" v-html="item.content"></div>
                    <!-- 时间 -->
                    <div class="rightTime">
                      {{ item.issue_time }}
                    </div>
                  </div>
                </div>
              </template>
              <!-- 如果搜索不到就显示这个 -->
              <el-empty description="查无此对象" v-else></el-empty>
            </div>
          </div>
        </ul>
        <!-- 分页 -->
        <el-pagination
          class="elPagination4"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="bzglarticle.page"
          :page-sizes="[100, 200, 300, 400]"
          :page-size="bzglarticle.paginate"
          layout="total, slot, prev, pager, next, jumper"
          :total="total"
        >
          <span class="paginationSpan"> 记录 </span>
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import breadcrumb from '@/components/Breadcrumb.vue'
import optionscard from '@/components/optionscard.vue'
export default {
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard,
    breadcrumb
  },
  data () {
    return {
      // 避震攻略文章参数
      bzglarticle: {
        pid: 1,
        paginate: 6,
        page: 1
      },
      total: 0,
      // 避震攻略文章数据
      bzglList: null,
      // 避震攻略分类数据
      classifyList: []
    }
  },
  created () {
    const id = this.$route.query.id
    console.log('id', id)
    this.getBzglClassify(id)
  },
  methods: {
    // 获取避震攻略文章数据
    async getBzglList () {
      const { res } = await this.$api.homepage.getBzgl(this.bzglarticle)
      console.log('this.bzglarticle', this.bzglarticle)
      console.log('避震res', res)
      for (const i of res.data) {
        //  删去内容里的html标签和空格 (/<img.*?><br\/?>/g, '')
        i.content = i.content.replace(/<img.*?><br\/?>/g, '')
        const reg = /<\/?.+?\/?>/g
        i.content = i.content.replace(reg, '')
        i.content = i.content.replace(/<\/?p>/g, '')
        i.content = i.content.replace(/&nbsp;/gi, '')
        // 去除时间后面的时分秒
        if (i.issue_time.length > 10) {
          i.issue_time = i.issue_time.slice(0, 10)
        }
      }
      console.log('res.data', res.data)
      this.bzglList = res.data
      this.total = res.total
    },
    // 获取避震攻略分类数据
    async getBzglClassify (id) {
      const { res } = await this.$api.homepage.getClassify({ pid: 1 })
      console.log('避震攻略res', res)
      this.classifyList = res
      console.log('this.classifyList', this.classifyList)
      if (!id) {
        this.bzglarticle.cate_id = this.classifyList[0].category_id
      } else {
        this.bzglarticle.cate_id = id
      }
      console.log(this.bzglarticle)
      this.getBzglList()
    },
    // 切换分类
    switchClassify (id, t) {
      this.bzglarticle.cate_id = id
      console.log('id', id)
      console.log('ttt', t)
      this.getBzglList()
    },
    // 跳转文章详情
    getArticle (id) {
      this.$router.push({
        path: '/homepage/bzglsecond/bzglthird',
        query: { id }
      })
    },
    // 每页几条
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
      this.bzglarticle.paginate = val
      this.getBzglList()
    },
    // 当前第几页
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
      this.bzglarticle.page = val
      this.getBzglList()
    }
  }
}
</script>

<style lang="scss" scoped>
.width1200 {
  width: 1200px;
  margin: 0 auto;
}

.ulul {
  padding: 0px;
  margin-top: 30px;
}

.middleDiv {
  display: flex;
  justify-content: space-between;
}

.classifyLeftDiv {
  background-color: #f7f7f7;
}

.classify-left {
  margin-left: 30px;
  height: 78px;
  line-height: 78px;
  color: #606060;
  font-size: 24px;
}

.classify-right {
  width: 850px;
  position: relative;
  margin-left: 60px;
}

.blue1 {
  padding-left: 30px;
  line-height: 60px;
  color: #fff;
  width: 250px;
  height: 66px;
  background-color: #2e88e4;
  list-style: none;
  cursor: pointer;
  font-size: 16px;
}

.white1 {
  padding-left: 30px;
  line-height: 60px;
  width: 250px;
  height: 66px;
  list-style: none;
  background-color: #f7f7f7;
  cursor: pointer;
  font-size: 16px;
}

.left-picture {
  transition: all 0.6s;
  width: 220px;
  height: 135px;
}

.left-picture:hover {
  transform: scale(1.2);
}

.rightWord {
  width: 600px;
}

.classify-right-div {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 50px;
}

.classify-right-div:hover .classify-title {
  color: #2e88e4;
}

.classify-title {
  font-size: 20px;
  color: #333333;
  margin-top: 15px;
  width: 480px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rightContent {
  font-size: 16px;
  color: #777777;
  margin-top: 20px;
  width: 480px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rightTime {
  font-size: 14px;
  color: #999999;
  margin-top: 20px;
  background-color: #f7f7f7;
  width: 100px;
  text-align: center;
}

.elPagination4 {
  margin: 0;
  margin-bottom: 40px;
  text-align: right;
}

.paginationSpan {
  font-weight: normal;
  color: #606060;
}
</style>
